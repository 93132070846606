import * as React from "react"
import { useRef, useState } from "react"

import clsx from "clsx"
import Layout from "../../Layout/layout"
import TaskLazyLoad from "./taskLazyLoad"

import "../../../css/jquery.dataTables.min.css"
import "../tasks.css"
import TaskListFilterAndSearch from "./taskListFilterAndSearch"
import TaskTable from "./taskTable"
import TaskTablePipeline from "./taskTablePipeline"
import Seo from "../../seo"
import { onGrabData } from "./taskListHelper"
import AddTaskModal from "./addTaskModal"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import { PIPELINE } from "../taskHelper"

const List = ({ addJob, transactionTypeId, listViewType }) => {
  const triggerRef = useRef(null)
  const [deleteTaskRow, setDeleteTaskRow] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchTransaction, setSearchTransaction] = useState(transactionTypeId)
  const [showModal, setShowModal] = useState(addJob)
  const [archivedTaskRow, setArchivedTaskRow] = useState(null)

  const { data, loading } = TaskLazyLoad({
    triggerRef,
    onGrabData,
    deleteTaskRow,
    setDeleteTaskRow,
    searchTerm,
    setSearchTerm,
    searchTransaction,
    setSearchTransaction,
    transactionTypeId,
    archivedTaskRow,
    setArchivedTaskRow,
  })

  return (
    <Layout>
      <Seo title="Tasks" />
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <AddTaskModal
              showModal={showModal}
              setShowModal={setShowModal}
              transactionTypeId={transactionTypeId}
            />
            <div className="row tabularMenuContainer">
              <div className="col">
                <TaskListFilterAndSearch setSearchTerm={setSearchTerm} listViewType={listViewType} />
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setShowModal(true)
                          }}
                        >
                          <i className="bi bi-plus-lg"></i>{" "}
                          <span className="d-none d-lg-inline">Add New</span>
                        </button>

                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu" data-popper-placement="bottom-start">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                setShowModal(true)
                              }}
                            >
                              <i className="bi bi-check-square"></i> Task / Project
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-folder-fill"></i> Folder /{" "}
                              <i className="bi bi-people-fill"></i> Workroom
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="nav-item" role="presentation">
                      <OverlayTrigger overlay={<Tooltip>List View</Tooltip>}>
                        <Link to="#" className={`nav-link ${!listViewType ? "active" : ""}`}>
                          <i className="bi bi-list-ol" />
                        </Link>
                      </OverlayTrigger>
                    </li>

                    <li className="nav-item" role="presentation">
                      <OverlayTrigger overlay={<Tooltip>Pipeline View</Tooltip>}>
                        <Link
                          to="?view=pipeline"
                          className={`nav-link ${listViewType === PIPELINE ? "active" : ""}`}
                        >
                          <i className="bi bi-bar-chart-fill rotate-270" />
                        </Link>
                      </OverlayTrigger>
                    </li>

                    <li className="nav-item nav-item-trash" role="presentation">
                      <a
                        className="nav-link"
                        id="tasks-trash-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#tasks-trash-pane"
                        role="tab"
                        aria-controls="tasks-trash-pane"
                        aria-selected="false"
                      >
                        <span data-bs-toggle="tooltip" title="Trash">
                          <i className="bi bi-trash-fill" />
                        </span>
                      </a>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop2"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                setShowModal(true)
                              }}
                            >
                              <i className="bi bi-check-square"></i> Task / Project
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-folder-fill"></i> Folder /{" "}
                              <i className="bi bi-people-fill"></i> Workroom
                            </a>
                          </li>
                        </ul>
                      </div>{" "}
                      <div className="btn-group btnDriveView dropdown">
                        <button
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-list-ol" />
                        </button>
                        <ul className="dropdown-menu" style={{}}>
                          <Link to="#" className={`dropdown-item ${!listViewType ? "active" : ""}`}>
                            <i className="bi bi-list-ol" /> List View
                          </Link>
                          <li>
                            <Link
                              to="?view=pipeline"
                              className={`dropdown-item ${listViewType === PIPELINE ? "active" : ""}`}
                            >
                              <i className="bi bi-bar-chart-fill rotate-270" /> Pipeline View
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-trash-fill" /> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 tableFadeOut">
                <div className="tab-content" id="taskListTabs">
                  <div
                    className="tab-pane fade show active"
                    id="tasks-tab-pane"
                    role="tabpanel"
                    aria-labelledby="tasks-tab"
                    tabIndex="0"
                  >
                    {listViewType === PIPELINE ? (
                      <TaskTablePipeline
                        data={data}
                        setDeleteTaskRow={setDeleteTaskRow}
                        setSearchTransaction={setSearchTransaction}
                        listViewType={listViewType}
                        transactionTypeId={transactionTypeId}
                        setArchivedTaskRow={setArchivedTaskRow}
                      />
                    ) : (
                      <TaskTable
                        data={data}
                        setDeleteTaskRow={setDeleteTaskRow}
                        setSearchTransaction={setSearchTransaction}
                        transactionTypeId={transactionTypeId}
                        setArchivedTaskRow={setArchivedTaskRow}
                      />
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tasks-trash-pane"
                    role="tabpanel"
                    aria-labelledby="task-tab"
                    tabIndex="0"
                  >
                    <table
                      id="prioritized_list_view_table"
                      className="table dataTable"
                      cellSpacing="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th width="5%">&nbsp;</th>
                          <th width="60%">
                            {/* <button
                              type="button"
                              className="btn btn-sm btnTHSoryByTags float-end me-3"
                              onclick="if (!window.__cfRLUnblockHandlers) return false; event.stopPropagation();"
                              data-bs-toggle="modal"
                              data-bs-target="#TasksSortByTagModal"
                              title="Advanced Tag Search"
                            >
                              <span
                                data-bs-toggle="tooltip"
                                title=""
                                data-bs-original-title="Advanced Tag Search"
                                aria-label="Advanced Tag Search"
                              >
                                <i className="bi bi-tags-fill"></i>
                              </span>
                            </button> */}
                          </th>
                          <th width="15%" className="d-none d-md-table-cell td-urgency">
                            Urgency
                          </th>
                          <th width="20%" className="text-end d-none d-lg-table-cell">
                            Last Activity / Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID1" className="css-checkbox" />
                              <label htmlFor="TC_ID1" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="task_detail.html">Rosa</a>
                            <span className="taskListCompany">SwiftCloud</span>
                            <div className="taskListQuickTools">
                              <a
                                className="taskEmail"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="rosa@domain.com"
                              >
                                <i className="bi bi-envelope-fill"></i>
                              </a>
                              <a
                                className="taskPhoneSMS"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="SMS to 9876543210"
                              >
                                <i className="bi bi-phone-fill"></i>
                              </a>
                              <a
                                className="taskPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="taskAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                            <div className="SwiftCloudTableTags">
                              <ul>
                                <li>
                                  <a href="#" data-tagname="Support">
                                    Support
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-01"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID2" className="css-checkbox" />
                              <label htmlFor="TC_ID2" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/susan.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="task_detail.html">Susan</a>
                            <div className="taskListQuickTools">
                              <a
                                className="taskPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="taskAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-15"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
      </div>
    </Layout>
  )
}

export default List
